/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function TrustpilotLP(props) {
    const style = {
        color: "#FFFFFF",
        fontWeight: "500",
        fontFamily: "'Inter Tight', sans-serif",
        fontSize: "18px",
        lineHeight: "18px",
        minHeight: "50px",
        width: "100%",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#FF6F1F",
        borderRadius: "5px",
        border: "1px solid #FF6F1F",
        padding: "4px",
    }

    return (
        <div
            class="trustpilot-widget"
            data-locale="en-US"
            data-template-id="5419b757fa0340045cd0c938"
            data-businessunit-id="61b90fe7d136c62d8cb4ecc1"
            data-style-height="25px"
            data-style-width="100%"
        >
            <a
                href="https://www.trustpilot.com/review/divorce.com"
                target="_blank"
                rel="noopener"
                style={style}
            >
                Read more reviews
            </a>
        </div>
    )
}
